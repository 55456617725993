import React from 'react'
import { Layout } from 'antd'

import MainLayout from '../../components/MainLayout'
import SEO from '../../components/seo'

const NewsPage = (props) => {
	const singleNews = props.pageContext

	return (
		<MainLayout>
			<SEO title='News' />

			<h1 className='distanced'>
				<span className='symbols'>-:-</span>
				<span>READ</span>
				<span className='symbols'>-:-</span>
			</h1>

			<Layout>
				<Layout.Content className='text-center'>
					{singleNews && (
						<div>
							<h1>{singleNews.title}</h1>
							<p>{singleNews.content}</p>
						</div>
					)}
				</Layout.Content>
			</Layout>
		</MainLayout>
	)
}

export default NewsPage
